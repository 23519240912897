export const personalInformationPolicy = [
  {
    text: 'Full name',
  },
  {
    text: 'Contact information (email address, phone number)',
  },
  {
    text: 'Demographic data - age, biological sex, tribe/clan information',
  },
  {
    text:
      'Professional background (work experience, education, skills, training, affiliations, achievements)',
  },
  {
    text: 'Resume or CV information',
  },
  {
    text: 'Job preferences (desired industry, location, salary range)',
  },
  {
    text: 'Profile photo (optional)',
  },
  {
    text:
      'Any additional information you choose to provide in your profile or application',
  },
];

export const usageInformationPolicy = [
  {
    text:
      'Device information (device type, operating system, unique device identifier)',
  },
  {
    text:
      'Log data (IP address, access times, app features accessed, pages visited)',
  },
  {
    text: 'Analytics data (usage patterns, user interactions, clicks)',
  },
];

export const providingServicesPolicy = [
  {
    text: 'Allowing you to create and manage your job seeker profile',
  },
  {
    text:
      'Facilitating job applications and submission of your CV to potential employers',
  },
  {
    text: 'Matching your skills and qualifications with job listings',
  },
  {
    text:
      'Notifying you of job opportunities and updates relevant to your preferences',
  },
  {
    text:
      'Communicating with you regarding your applications, interview invitations, and job offers',
  },
  {
    text: 'Facilitating communication between you and potential employers',
  },
];

export const improvingAndPersonalizingTheApp = [
  {
    text:
      'Analyzing usage patterns and trends to improve our services and user interface',
  },
  {
    text: 'Providing personalized job recommendations and suggestions',
  },
  {
    text:
      'Sending you relevant notifications and updates based on your preferences and activity',
  },
  {
    text:
      'Conducting research and data analysis to improve our services and user experience',
  },
  {
    text:
      'Aggregating and anonymizing data for statistical and research purposes',
  },
];

export const legalComplianceAndSecurityPolicy = [
  {
    text: 'Comply with applicable laws, regulations, and legal obligations',
  },
  {
    text: 'Protect the security and integrity of the App and our users',
  },
  {
    text: 'Investigate and prevent fraudulent or unauthorized activities',
  },
  {
    text: 'Enforce our Terms of Service and other applicable policies',
  },
];

import React from 'react';

const MobilePrivacyPolicySubHeader = () => {
  return (
    <div className="mobile-privacy-policy-sub-header-container">
      <div className="sub-header-title">Privacy Policy</div>
      <div className="sub-header-date">Updated Date: Jul 20, 2023</div>
    </div>
  );
};

export default MobilePrivacyPolicySubHeader;

import React from 'react';
import Layout from '@components/Layout/LayoutTemplates/JobSeekersLayout';
import SEO from '@components/SEO';

import MobilePrivacyPolicySubHeader from './MobilePrivacyPolicySubHeader/';
import PrivacyPolicyAndTermAndConditionsFooter from '@components/Layout/Footer/PrivacyPolicyAndTermAndConditionsFooter';
import IconArrowRight from '@components/Icons/IconArrowRight';
import {
  personalInformationPolicy,
  usageInformationPolicy,
  providingServicesPolicy,
  improvingAndPersonalizingTheApp,
  legalComplianceAndSecurityPolicy,
} from './helpers/index';

const PrivacyPolicy = () => {
  return (
    <Layout isMobileComingSoon={false}>
      <SEO title="Privacy Policy" />
      <div className="mobile-privacy-policy-container">
        <MobilePrivacyPolicySubHeader />
        <div className="mobile-privacy-policy-content">
          <div className="content-title">
            Please note that Wan PNG is a property of Kumul Petroleum Holding
            Limited (KPHL).
          </div>
          <div className="description">
            This Privacy Policy describes how WAN PNG collects, uses, and
            protects the information you provide while using our Jobseeker
            Mobile App (“the App”). We are committed to ensuring the privacy and
            security of your personal information. By using the App, you consent
            to the practices described in this Privacy Policy.
          </div>
          <div className="title">Information We Collect</div>
          <div className="content-title">1.1 Personal Information</div>
          <div className="content-description">
            When you use the App, we may collect the following personal
            information from you:
          </div>
          <div className="content-list-container">
            {personalInformationPolicy.map((policy, index) => (
              <div className="content-list" key={index}>
                <IconArrowRight />
                {policy.text}
              </div>
            ))}
          </div>
          <div className="content-title">1.2 Usage Information</div>
          <div className="content-description">
            We may also collect certain non-personal information about your use
            of the App, such as:
          </div>
          <div className="content-list-container">
            {usageInformationPolicy.map((policy, index) => (
              <div className="content-list" key={index}>
                <IconArrowRight />
                {policy.text}
              </div>
            ))}
          </div>
          <div className="title">How We Use Your Information</div>
          <div className="content-title">2.1 Providing Services</div>
          <div className="content-description">
            We use the information we collect to provide you with the services
            and features of the App, including:
          </div>
          <div className="content-list-container">
            {providingServicesPolicy.map((policy, index) => (
              <div className="content-list" key={index}>
                <IconArrowRight />
                {policy.text}
              </div>
            ))}
          </div>
          <div className="content-title">
            2.2 Improving and Personalizing the App
          </div>
          <div className="content-description">
            We may use your information to enhance and personalize your
            experience on the App, including:
          </div>
          <div className="content-list-container">
            {improvingAndPersonalizingTheApp.map((policy, index) => (
              <div className="content-list" key={index}>
                <IconArrowRight />
                {policy.text}
              </div>
            ))}
          </div>
          <div className="content-title">2.3 Legal Compliance and Security</div>
          <div className="content-description">
            We may use your information to:
          </div>
          <div className="content-list-container">
            {legalComplianceAndSecurityPolicy.map((policy, index) => (
              <div className="content-list" key={index}>
                <IconArrowRight />
                {policy.text}
              </div>
            ))}
          </div>
          <div className="title">Information Sharing</div>
          <div className="content-title">3.1 Sharing with Employers</div>
          <div className="content-description-2">
            When you apply for a job through the App, we may share your profile
            and CV information with the relevant employers or recruiters.
            However, we will only share the information necessary for the job
            application process and as authorized by you. Employers agree to
            comply with all their responsibilities under applicable data
            protection rules with respect to the collection, processing and
            storage of Personal Data, as well as providing adequate protection
            of all data subject rights provided for under all applicable data
            protection rules.
          </div>
          <div className="content-title">3.2 Service Providers</div>
          <div className="content-description-2">
            We may engage third-party service providers to perform certain
            functions on our behalf, such as hosting the App, analyzing data,
            sending notifications, or facilitating communication. These service
            providers are carefully selected and are contractually obligated to
            handle your information in a confidential and secure manner. They
            are authorized to access your information only to the extent
            necessary to perform their specific functions on our behalf. We
            require them to adhere to industry best practices and data
            protection regulations to maintain the confidentiality and security
            of your data.
            <br />
            You may contact <b>privacy@wanpng.com</b> to avail of an up-to-date
            list of third-party service providers.
          </div>
          <div className="content-title">
            3.3 Aggregated and Anonymized Data
          </div>
          <div className="content-description-2">
            We may aggregate and anonymize the information collected from users
            to generate statistical and research reports. This aggregated and
            anonymized data does not identify individual users and may be shared
            with third parties for various purposes, including marketing,
            analytics, and industry research.
          </div>
          <div className="content-title">3.4 Legal Requirements and Safety</div>
          <div className="content-description-2">
            We may disclose your information when required by law, court order,
            or government regulation, or if we believe it is necessary to
            protect the rights, property, or safety of our users, ourselves, or
            others.
          </div>
          <div className="title">Data Security</div>
          <div className="content-description-2">
            We take reasonable measures to protect the security of your
            information and prevent unauthorized access, use, or disclosure.
            However, please be aware that no security measures are 100%
            foolproof, and we cannot guarantee the absolute security of your
            data.
          </div>
          <div className="title">Your Choices and Right</div>
          <div className="content-title">5.1 Access and Correction</div>
          <div className="content-description-2">
            You have the right to access, review, and correct the personal
            information in your profile. You can update your profile information
            within the App or contact us directly for assistance.
          </div>
          <div className="content-title">5.2 Account Deletion</div>
          <div className="content-description-2">
            If you wish to delete your account and associated information from
            the App, please contact us using the information provided below.
            Please note that deleting your account will result in the permanent
            removal of your profile, applications, and related data.
          </div>
          <div className="content-title">5.3 Communications Preferences</div>
          <div className="content-description-2">
            You can manage your communication preferences within the App,
            including opting in or out of certain notifications and updates. You
            may also unsubscribe from promotional emails by following the
            instructions provided in the email.
          </div>
          <div className="content-title">5.4 Legal Rights</div>
          <div className="content-description-2">
            Subject to applicable laws, you may have certain rights regarding
            your personal information, including the right to request access,
            rectification, erasure, or restriction of processing. If you would
            like to exercise any of these rights or have any privacy-related
            inquiries, please contact us using the information provided below.
          </div>
          <div className="title">Children’s Privacy</div>
          <div className="content-description-2">
            The App is not intended for use by individuals under the age of 18.
            We do not knowingly collect personal information from children under
            the age of 18. If we become aware that we have inadvertently
            collected personal information from a child under the age of 18, we
            will take reasonable steps to delete such information from our
            records.
          </div>
          <div className="title">Changes to this Privacy Policy</div>
          <div className="content-description-2">
            We reserve the right to update or modify this Privacy Policy from
            time to time. We will notify you of any material changes by posting
            the revised Privacy Policy within the App or by other means as
            required by applicable laws. Your continued use of the App after the
            effective date of the revised Privacy Policy constitutes your
            acceptance of the changes.
          </div>
          <div className="title">Contact Us</div>
          <div className="content-description">
            If you have any questions, concerns, or requests regarding this
            Privacy Policy or our privacy practices, please contact us at:
          </div>
          <div className="spacer-bottom" />
          <div className="content-title">Email</div>
          <div className="email-text">support@wanpng.com</div>
          <div className="content-title">Postal Mail</div>
          <div className="content-description">
            Wan PNG Privacy Department Kumul Petroleum Holdings Limited (KPHL)
            P.O. Box 143 Port Moresby, Papua New Guinea 121 NCD
          </div>
          <div className="spacer-bottom" />
          <div className="content-description">
            Please allow a reasonable time for us to respond to your inquiries
            and requests.
          </div>
          <div className="content-description-2">
            By using the Jobseeker Mobile App, you acknowledge that you have
            read and understood this Privacy Policy and agree to the collection,
            use, and disclosure of your information as described herein.{' '}
          </div>
          <div className="spacer-bottom-2" />
          <PrivacyPolicyAndTermAndConditionsFooter />
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
